.card-profile .card-avatar {
  margin-top: 0 !important;
}

.card-image img {
  width: 100%;
}

.card-wrapper {
  // height: 450px;
}

// .card-footer {
//   position: absolute;
//   bottom: 0;
// }
